/*
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Header from './header'
import './layout.css'
import { todosRequested } from '../state/actions/todos'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import { GlobalStyle } from '../styled/GlobalStyle'
import theme from '../styled/Theme'
import { Container } from '@material-ui/core'

const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
  },
})


const Layout = ({ children }: { children: any }) => {

  const dispatch = useDispatch()

  useEffect(() => {

    /*
     * This is an example of doing things when the app first loads.
     * You can dispatch a Redux action here to do some async thing
     * when the webapp boots up.
     */

    dispatch(todosRequested())

  }, [])

  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)

  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <Container maxWidth={false} disableGutters={true}
                 style={{ height: '100vh', flexDirection: 'column', display: 'flex' }}>
        <Header siteTitle={data.site.siteMetadata.title}/>
        <div
          style={{
            margin: '0 auto',
            width: '100%',
            maxWidth: 960,
            padding: '0px 1.0875rem 1.45rem',
            paddingTop: 0,
            flexGrow: 1,
          }}
        >
          <main className={classes.mainContainer}>{children}</main>
          <footer>
            © {new Date().getFullYear()}, Built with
            {' '}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
            {' '}
            by
            {' '}
            <a href="https://www.evaluates2.com">Evaluates2</a>
            .
          </footer>
        </div>
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
