import React from 'react'
import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import logo from '../assets/logo.gif'
import { headerStyles } from '../styled/Theme'
import { useUser } from '../Looking4Beer/Users/Services/UserContext'
import { LoginButton, LogoutButton } from '../Looking4Beer/Users'

const Header = ({ siteTitle = '' }: { siteTitle: string }) => {
  const classes = headerStyles()

  const { user } = useUser()
  console.log(user)
  return (
    <AppBar position={'static'}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="Menu">
          <MenuIcon/>
        </IconButton>
        <img height={'78px'} src={logo} alt={'Looking For Beer'}/>
        <Typography variant="h1" classes={{ root: classes.logo }}>
          Looking 4 Beer
        </Typography>
        {!user ? <LoginButton/> : <LogoutButton/>}
        {/*{!user*/}
        {/*  ?*/}
        {/*  <Fragment><Button variant='contained' component={Link} to='/login'*/}
        {/*                    color='primary'>Login</Button></Fragment>*/}
        {/*  : <Fragment>*/}
        {/*    <IconButton*/}
        {/*      ref={anchorRef}*/}
        {/*      color='inherit'*/}
        {/*      onClick={handleToggle}*/}
        {/*      aria-owns={open ? 'menu-list-grow' : undefined}*/}
        {/*      aria-haspopup="true">*/}
        {/*      <Avatar>GQ</Avatar>*/}
        {/*    </IconButton>*/}
        {/*    <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>*/}
        {/*      {({TransitionProps, placement}) => (*/}
        {/*        <Grow*/}
        {/*          {...TransitionProps}*/}
        {/*          style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}*/}
        {/*        >*/}
        {/*          <Paper id="menu-list-grow">*/}
        {/*            <ClickAwayListener onClickAway={handleClose}>*/}
        {/*              <MenuList>*/}
        {/*                <MenuItem onClick={() => console.log("Profile")}>Profile</MenuItem>*/}
        {/*                <MenuItem onClick={logout}>Logout</MenuItem>*/}
        {/*              </MenuList>*/}
        {/*            </ClickAwayListener>*/}
        {/*          </Paper>*/}
        {/*        </Grow>*/}
        {/*      )}*/}
        {/*    </Popper>*/}
        {/*  </Fragment>}*/}
      </Toolbar>
    </AppBar>
  )
}

export default Header
